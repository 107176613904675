import React, { useEffect } from "react";
import * as d3 from "d3";
import logo from "./assets/images/logo.png";
import ApiRequest from "./restservice";
import { errorToast } from "./showtoast";
let account = "";
var treemap01,
  svg01,
  i = 0,
  root01,
  duration01 = 750,
  selected01 = null,
  nodeChildrenData = {};
function SponsorTree({ accounttoken }) {
  useEffect(() => {
    if (accounttoken) GetDashboardDetails();
  }, [accounttoken]);
  const GetDashboardDetails = async () => {
    let res = await ApiRequest("GetDashboardDetails.asp", "GET", null, {
      Authorization: accounttoken,
    });
    if (res.status === "error") {
      errorToast(res.message);
      return;
    }
    console.log(res);
    account = {
      memberid: accounttoken,
      refferalcount: res.directreferrals,
    };
    await processTree();
  };
  const viewUserReferrals = async (memberid) => {
    var json = await ApiRequest(
      "directsponsorreport.asp",
      "POST",
      JSON.stringify({
        memberid: memberid,
      }),
      {
        Authorization: accounttoken,
      },
      true
    );
    console.log(json);
    if (json.status !== "error") {
      return json.items;
    } else {
      return [];
    }
  };

  const processTree = async (_elementId = "#tree", _userAddress = account) => {
    var array01 = [];
    var rootData01 = await getTreeData(_userAddress);
    var node01 = await renderTree01(_elementId, rootData01["treeData"]);
    if (rootData01.children) {
      for (var k = 0; k < rootData01.children.length; k++) {
        array01.push({
          address: rootData01.children[k],
          selected: node01,
        });
      }
      for (var l = 0; l < array01.length && l < 65536; l++) {
        var element01 = array01[l];
        var data01 = await getTreeData(element01["address"]);
        await addChildren01(element01["selected"], data01["treeData"]);
      }
    }
  };
  const renderTree01 = async (elementId = "#tree", json_data = {}) => {
    var margin_top = 20,
      margin_right = 10,
      margin_bottom = 20,
      margin_left = 30,
      width = window.innerWidth - margin_left - margin_right,
      height = window.innerHeight - margin_top - margin_bottom;
    svg01 = d3
      .select(elementId)
      .append("svg")
      .attr("width", width + margin_right + margin_left)
      .attr("height", height + margin_top + margin_bottom)
      .call(
        d3.zoom().on("zoom", function (event) {
          svg01.attr("transform", event.transform);
        })
      )
      .append("g")
      .attr("transform", "translate(" + margin_left + "," + margin_top + ")");
    treemap01 = d3.tree().size([height, width]);
    root01 = d3.hierarchy(json_data, function (d) {
      return d.children;
    });
    root01.x0 = height / 2;
    root01.y0 = 0;
    var node = await update01(root01);
    return node;
  };
  const toggle = (d) => {
    if (d.children) {
      d._children = d.children;
      d.children = null;
    } else {
      d.children = d._children;
      d._children = null;
    }
  };
  const update01 = async (source) => {
    var treeData = treemap01(root01),
      nodes = treeData.descendants(),
      links = treeData.descendants().slice(1);
    nodes.forEach(function (d) {
      d.y = 300 * d.depth;
    });
    var link = svg01.selectAll("path.link").data(links, function (d) {
      return d.id;
    });
    link
      .enter()
      .insert("path", "g")
      .attr("class", "link")
      .attr("d", function () {
        var o = {
          x: source.x0,
          y: source.y0,
        };
        return diagonal(o, o);
      })
      .merge(link)
      .transition()
      .duration(duration01)
      .attr("d", function (d) {
        return diagonal(d, d.parent);
      });
    link
      .exit()
      .transition()
      .duration(duration01)
      .attr("d", function () {
        var o = {
          x: source.x,
          y: source.y,
        };
        return diagonal(o, o);
      })
      .remove();

    function diagonal(s, d) {
      return `M ${s.y} ${s.x}\n            C ${(s.y + d.y) / 2} ${
        s.x
      },\n              ${(s.y + d.y) / 2} ${d.x},\n              ${d.y} ${
        d.x
      }`;
    }
    nodes.forEach(function (d) {
      d.x0 = d.x;
      d.y0 = d.y;
    });
    var node = svg01.selectAll("g.node").data(nodes, function (d) {
      return d.id || (d.id = ++i);
    });
    var nodeEnter = node
      .enter()
      .append("g")
      .attr("class", "node")
      .style("cursor", function () {
        return "pointer";
      })
      .attr("transform", function () {
        return "translate(" + source.y0 + "," + source.x0 + ")";
      })
      .on("click", click);
    nodeEnter
      .append("image")
      .attr("xlink:href", logo)
      .attr("x", "-20px")
      .attr("y", "-20px")
      .attr("width", "40px")
      .attr("height", "40px");
    nodeEnter
      .append("a")
      .attr("xlink:href", function (d) {
        return d.url;
      })
      .append("svg:text")
      .attr("x", function (d) {
        return d.children || d._children;
      })
      .attr("dy", "-10px")
      .attr("dx", "-5px")
      .style("z-index", "99")
      .style("font-size", "20px")
      .attr("text-anchor", function (d) {
        return d.children || d._children ? "end" : "start";
      })
      .html(function (d) {
        return d.data.refferalcount;
      })
      .style("fill", function () {
        return "green";
      })
      .style("font-weight", function () {
        return "bold";
      })
      .style("text-align", function () {
        return "center";
      });
    function truncateMiddle(str, maxLength) {
      if (str.length <= maxLength) return str;
      const ellipsis = "...";
      const ellipsisLength = ellipsis.length;
      const charsToShow = maxLength - ellipsisLength;
      const startChars = Math.floor(charsToShow / 2);
      const endChars = charsToShow - startChars;
      const start = str.slice(0, startChars);
      const end = str.slice(-endChars);
      return start + ellipsis + end;
    }
    nodeEnter
      .append("a")
      .append("text")
      .attr("x", function (d) {
        return d.children || d._children ? -40 : 40;
      })
      .style("fill", "Green")
      .style("font-weight", "bold")
      .style("font-size", "16px")
      .selectAll("tspan") // Create multiple tspans for each line
      .data(function (d) {
        return ["ID : " + truncateMiddle(d.data.memberid, 10)];
      })
      .enter()
      .append("tspan") // Append tspans to the text element
      .attr("x", function () {
        // Position tspans with the same x attribute
        return d3.select(this.parentNode).attr("x");
      })
      .attr("dy", function (d, i) {
        console.log(d);
        return i === 0 ? "0" : "1.2em";
      })
      .text(function (d) {
        return d;
      });
    nodeEnter.append("svg:title").text(function (d) {
      return (
        "ID : " + d.data.memberid + "\nDirect : " + d.data.refferalcount + "\n"
      );
    });
    // , nodeEnter.append("svg:title").html((function (d) {
    // 	return tronWeb.address.fromHex(d.data.memberid)
    // }));
    var nodeUpdate = nodeEnter.merge(node);
    async function click(e, d) {
      console.log(e);
      if (!d.working) {
        d.working = !0;
        var _childrenLength = d._children ? d._children.length : 0,
          childrenLength = d.children ? d.children.length : 0;
        if (
          (!d.children && !d._children) ||
          (0 === _childrenLength && 0 === childrenLength) ||
          _childrenLength > 2 ||
          childrenLength > 2
        )
          if (
            nodeChildrenData[d.data.memberid] &&
            2 === nodeChildrenData[d.data.memberid].length
          ) {
            d._children = new Array();
            d.data.children = new Array();
            for (let i = 0; i < nodeChildrenData[d.data.memberid].length; i++) {
              let data = nodeChildrenData[d.data.memberid][i];
              let json_data = data.treeData,
                newNode;
              newNode = d3.hierarchy(json_data);
              newNode.depth = d.depth + 1;
              newNode.height = d.height - 1;
              newNode.parent = d;
              newNode.id = Date.now();
              d._children.push(newNode);
              d.data.children.push(newNode.data);
            }
          } else {
            d._children = new Array();
            d.data.children = new Array();
            var children = await viewUserReferrals(d.data.memberid);
            if (0 === children.length) return;
            if (children.length > 0) {
              for (let xi = 0; xi < children.length; xi++) {
                let data = await getTreeData(children[xi]);
                let json_data = data.treeData,
                  newNode;
                newNode = d3.hierarchy(json_data);
                newNode.depth = d.depth + 1;
                newNode.height = d.height - 1;
                newNode.parent = d;
                newNode.id = Date.now();
                d._children.push(newNode);
                d.data.children.push(newNode.data);
              }
            }
          }
        toggle(d);
        update01(d);
        d.working = false;
      }
    }
    return (
      nodeUpdate
        .transition()
        .duration(duration01)
        .attr("transform", function (d) {
          return "translate(" + d.y + "," + d.x + ")";
        }),
      nodeUpdate
        .select("circle.node")
        .attr("r", 10)
        .style("fill", function () {
          return "#0e4677";
        })
        .attr("cursor", "pointer"),
      node
        .exit()
        .transition()
        .duration(duration01)
        .attr("transform", function () {
          return "translate(" + source.y + "," + source.x + ")";
        })
        .remove()
        .select("circle")
        .attr("r", 0),
      nodes.forEach(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
        selected01 = d;
      }),
      selected01
    );
  };
  const getTreeData = async (_userAddress = account) => {
    console.log(_userAddress);
    return {
      treeData: {
        memberid: _userAddress.memberid,
        refferalcount: _userAddress.refferalcount,
        children: [],
      },
    };
  };
  const addChildren01 = async (selected01, json_data = {}) => {
    var newNode = d3.hierarchy(json_data);
    newNode.depth = selected01.depth + 1;
    newNode.height = selected01.height - 1;
    newNode.parent = selected01;
    newNode.id = Date.now();
    if (!selected01.children) selected01.children = [];
    if (!selected01.data.children) selected01.data.children = [];
    selected01.children.push(newNode);
    selected01.data.children.push(newNode.data);
    var node = await update01(selected01);
    return node;
  };
  return (
    <div className="col-lg-12" style={{overflow:'hidden'}}>
      <div id="tree" style={{ overflow: "auto !important" }}></div>
    </div>
  );
}

export default SponsorTree;
