export default function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row align-items-center flex-row-reverse">
                    <div className="col-md-12 col-sm-12 mt-3 mt-lg-0 text-center"> Copyright © 2024 Maxim Academy. All rights reserved. </div>
                </div>
            </div>
        </footer>
    )
}