import { toast } from "react-toastify";
function successToast(message) {
    toast(message, {
        type: "success",
        position: "top-right",
        autoClose: 3000
    })
}
function errorToast(message) {
    toast(message, {
        type: "error",
        position: "top-right",
        autoClose: 3000
    })
}
export function copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    successToast("Copied");
}

function OnLogout() {
    localStorage.clear();
}
const ParticlesArray = {
    particles: {
        number: {
            value: 40,
            density: {
                enable: true,
                value_area: 800
            }
        },
        color: {
            value: '#fff'
        },
        shape: {
            type: 'circle',
            stroke: {

                width: 0,
                color: '#ff0000'
            },
            polygon: {
                nb_sides: 5
            },
            image: {
                src: '',
                width: 100,
                height: 100
            }
        },
        opacity: {
            value: 1,
            random: false,
            anim: {
                enable: false,
                speed: 2,
                opacity_min: 0,
                sync: false
            }
        },
        size: {
            value: 2,
            random: false,
            anim: {
                enable: false,
                speed: 10,
                size_min: 0,
                sync: false
            }
        },
        line_linked: {
            enable: true,
            distance: 100,
            color: '#fff',
            opacity: 1,
            width: 1
        },
        move: {
            enable: true,
            speed: 2,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
                enable: false,
                rotateX: 3000,
                rotateY: 3000
            }
        },
        array: []
    },
    interactivity: {
        detect_on: 'canvas',
        events: {
            onhover: {
                enable: true,
                mode: 'grab'
            },
            onclick: {
                enable: true,
                mode: 'push'
            },
            resize: true
        },
        modes: {
            grab: {
                distance: 100,
                line_linked: {
                    opacity: 1
                }
            },
            bubble: {
                distance: 200,
                size: 80,
                duration: 0.4
            },
            repulse: {
                distance: 200,
                duration: 0.4
            },
            push: {
                particles_nb: 4
            },
            remove: {
                particles_nb: 2
            }
        },
        mouse: {}
    },
    retina_detect: false,
    fn: {
        interact: {},
        modes: {},
        vendors: {}
    },
    tmp: {}
};
export {
    successToast, errorToast, ParticlesArray, OnLogout
}