import { ToastContainer } from "react-toastify";
import Dashboard from "./dashboard";
import Footer from "./footer";
import Header from "./header";

function App() {
  // const RenderViews = ({ path }) => {
  //   if (path === '/faq')
  //     return <FAQ />;
  //   else if (path === '/kyc')
  //     return <KYC />;
  //   else if (path.includes('/home'))
  //     return <Dashboard />;
  //   else if (path === '/profile')
  //     return <Profile />;
  //   else if (path === '/settings')
  //     return <Settings />;
  // }
  return (
    <div className="">
      <ToastContainer />
      <div id="global-loader" style={{ display: "none" }}></div>
      
      <div className="page">
        <div className="page-main">
          <Header />
          {/* <HorizontalMenu activeMenu='/home' /> */}
          <Dashboard />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
