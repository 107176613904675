import AssetImages from "./assetimages";

export default function Header() {
  return (
    <div className="sticky">
      <header className="hor-header header-bg header">
        <div className="container">
          <div className="d-flex align-items-center">
            {/* <label className="animated-arrow hor-toggle horizontal-navtoggle">
                        <span></span>
                    </label> */}
            <label className="header-brand mb-0">
              <img
                alt="Logo"
                className=""
                src={AssetImages.logo}
                style={{ maxHeight: 60 }}
              />
            </label>
            <h2 className="text-primary mb-0">MAXIM</h2>
          </div>
        </div>
      </header>
    </div>
  );
}
