import React from 'react';
import { motion } from 'framer-motion';

const AnimatedNumber = ({ value,formatValue }) => {
  return (
    <motion.div
      key={value || 0}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      {parseFloat(value || 0).toFixed(formatValue)}
    </motion.div>
  );
};

export default AnimatedNumber;
